/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

function MembershipFAQ({ noHeader, lang }) {
  const [questions, setQuestions] = useState([]);
  const toggleAnswer = (id, e) => {
    e.preventDefault();
    const cloneQuestions = [...questions];
    cloneQuestions.forEach((faq) => {
      if (faq.id === id) {
        faq.isOpen = !faq.isOpen;
      }
    });
    setQuestions(cloneQuestions);
  };

  const faq = {
    en: [
      {
        id: 1,
        title: 'How do I join?',
        answer: 'Your email will contain a login link. Click through to access your membership.',
      },
      {
        id: 2,
        title: 'Where can I track progress?',
        answer: 'You can track your progress by logging into your Baraka bucks account and going to your benefits page.',
      },
      {
        id: 3,
        title: 'How do I earn?',
        answer: 'There are many ways to earn, through purchasing our products, referring your friends, following us on Instagram, and more!',
      },
      {
        id: 4,
        title: 'Do points expire or reset?',
        answer: 'Your points will expire after 1 year of inactivity. To maintain your points balance, simply use them or earn more.',
      },
    ],
  };

  useEffect(() => {
    setQuestions(faq.en);
  }, []);

  return (
    <div className="asset-faq">
      {!noHeader
      && <h2 className="digital-asset__subheader -center">FAQ</h2>}
      {questions.map((faq) => (
        <div
          key={faq.id}
          className="asset-faq__item">
          {faq.isOpen
            ? (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                -
              </a>
            )
            : (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                +
              </a>
            )}
          <div
            className="asset-faq__item--question"
            onClick={(e) => toggleAnswer(faq.id, e)}>
            {faq.title}
          </div>
          <div
            className={faq.isOpen ? 'asset-faq__item--answer -open' : 'asset-faq__item--answer'}
            dangerouslySetInnerHTML={{ __html: faq.answer }}>
          </div>
        </div>
      ))}
      
    </div>
  );
}

export default MembershipFAQ;
