import React, { useState, useEffect } from 'react';

const HowItWorks = ({ lang }) => {
  // const [transferCode, setTransferCode] = useState(null);
  const copyStrings = {
    en: {
      heading: 'How does this work?',
    },
  };

  const instructions = {
    en: [
      {
        id: 1,
        title: 'Join',
        description: 'By entering your email, you’re a member! We’re happy you signed up.',
      },
      {
        id: 2,
        title: 'Check your email',
        description: 'Your email will contain your login link. Click through to explore your membership.',
      },
      {
        id: 3,
        title: 'Start earning',
        description: 'Enjoy benefits like savings and freebies. Use your points to save money, get free merchandise and special access. Our membership is designed to bring the best offers to you in one place.',
      },
    ],
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="how-it-works__wrapper">
      <div id="startContent" className="how-it-works">
        <div className="how-it-works__title">
          {copyStrings[lang].heading}
        </div>
        <div className="how-it-works__list">
          {instructions[lang].map((step, index) => (
            <div key={step.id} className="how-it-works__item">
              <div className="how-it-works__number">{index + 1}</div>
              <div className="how-it-works__step-title">{step.title}</div>
              <div className="how-it-works__step-description">{step.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default HowItWorks;
